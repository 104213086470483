// Api BASE URLs
export const API_URL = 'https://api.meds-on-wheels.com';
// export const API_URL = 'http://api-test.meds-on-wheels.com/';
// export const API_URL = 'http://localhost:5004';
// export const API_URL = 'https://medsonwheels-api.ondemandcreations.com';
export const API_VERSION = '/api/v1';

export const APP_NAME = "Meds On Wheels"
export const PANEL_NAME = "Admin"

// COMMON CONSTANT
export const API_TOKEN = localStorage.getItem('user_id');
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
export const PASS_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
export const Numeric = /^[0-9]+$/
export const ALPHABET = /^[A-Za-z]+$/
export const ALPHANUMERIC_REGEX = /[^a-z\d]/i;
export const PASS_VALIDATION_MSG = 'password should be at least 8 characters, one uppercase, lowercase, special character, numeric value.'

// AUTH URL
export const API_LOGIN = '/admin/adminLogin';


// DASHBOARD
export const DASHBOARD = '/adminDashboard/getDashboardList';

// Stores
export const API_GET_STORES_URL = '/store/';
export const API_GET_STORES_BY_ID = '/store/getstorebyId/';
export const API_ADD_STORES = '/store/register/';
export const API_EDIT_STORES = '/store/updatestore/';
export const API_ADD_STORE_BANKAC = '/store/addaccount/';
export const API_ADD_STORE_DOCS = '/store/addStoreDoc/';
export const API_GET_STORE_DOCS = '/store/getStoreDoc/';

// Store's Categories
export const API_GET_STORES_CATEGORIES = '/storecategory/';
export const API_GET_STORES_CATEGORIE_BY_ID = '/storecategory/edit/';
export const API_ADD_STORES_CATEGORIE = '/storecategory/add/';
export const API_EDIT_STORES_CATEGORIE = '/storecategory/update';
export const API_DELETE_STORES_CATEGORIE = '/storecategory/remove';

export const API_CATEGORY_ADD_URL = '/category/add';
export const API_CATEGORY_LIST_URL = '/category';
export const API_CATEGORY_GET_URL = '/category/addsubcategory';
export const API_CATEGORY_UPDATE_URL = '/category/updatesubcategory';
export const API_CATEGORY_DELETE_URL = '/category/remove';

export const API_GET_SUBCATEGORY = "/category/allsubcategory"
export const API_GET_SUBCATEGORY_BY_ID = "/category/editsubcat/"
export const API_ADD_SUBCATEGORY = "/category/addsubcategory"
export const API_EDIT_SUBCATEGORY = "/category/updatesubcategory"

export const API_GET_RESTAURANTS_URL = '/admin/getPharmacyWithFilter';
export const API_GET_RESTAURANTS_All = '/admin/restaurantlist';
export const API_GET_PHARMACIES_All = '/admin/pharmacylist';
export const API_GET_PHARMACIES_EXPENSE = '/admin/pharmaciesExpense'
export const API_ADD_RESTAURANT = '/admin/addPharmacy/';
export const API_EDIT_RESTAURANT = '/admin/editPharmacy/';
export const API_RESTAURANTS_DELETE_URL = '/admin/removePharmacy';
export const API_RESTAURANTS_UPDATE_SERVICES_URL = '/admin/pauseOrResumeServices';
export const API_REFUND_ORDERS = '/admin/orders/refundOrder';

export const API_GET_RESTAURANT_BY_ID = '/admin/getPharmacyById/';
export const API_RESTAURANTS_STATUS_URL = '/admin/pharmacy/updatestatus';
export const API_RESTAURANTS_ACCESS_URL = '/admin/pharmacy/editFeatureAccess';
export const API_RESTAURANTS_ORG_ID = '/admin/pharmacy/createUberOrgId';
export const API_RESTAURANTS_PHARMACY_PRICE_URL = '/admin/pharmacy/editPharmacyPrice';
export const API_RESTAURANTS_OVERTIME_PRICE_URL = '/admin/pharmacy/editOvertimePrice';
export const API_GET_PHARMACY_ORDERS = '/admin/orders/getPharmacyOrderById'

export const API_RESTAURANTS_ADD_URL = '/admin/restaurant/add';
export const API_GET_RESTAURANTS_PAYMENTS = '/admin/restaurantpaymentshistory/';
export const API_RESTAURANTS_PAYMENTS = '/admin/paytorestaurant/';
// Category
export const API_GET_CATEGORY_URL = '/category/';
export const API_GET_CATEGORY = '/category/edit/';
export const API_ADD_CATEGORY = '/category/add/';
export const API_EDIT_CATEGORY = '/category/update/';
export const API_DELETE_CATEGORY = '/category/remove/';


export const API_ADD_UNIT = "/adminUnit/addUnit/";
export const API_EDIT_UNIT = "/adminUnit/UpdateUnitData/";
export const API_GET_UNITS = "/adminUnit/getUnitList/";
export const API_GET_ENABLE_UNITS = "/adminUnit/getEnabledUnitList/";
export const API_GET_UNIT_BY_ID = "/adminUnit/getUnitDetailsById/";
export const API_REMOVE_UNIT = "/adminUnit/removeUnitData/";

export const API_CUISINES_LIST_URL = '/admin/cuisines';
export const API_CUISINES_ADD_URL = '/admin/cuisines/add';
export const API_CUISINES_GET_URL = '/admin/cuisines/view';
export const API_CUISINES_UPDATE_URL = '/admin/cuisines/update';
export const API_CUISINES_DELETE_URL = '/admin/cuisines/remove';

export const API_PROMOTIONS_LIST_URL = '/admin/promotions';
export const API_PROMOTIONS_ADD_URL = '/admin/promotions/add';
export const API_PROMOTIONS_GET_URL = '/admin/promotions/view';
export const API_PROMOTIONS_UPDATE_URL = '/admin/promotions/update';
export const API_PROMOTIONS_DELETE_URL = '/admin/promotions/remove'
// Store's Items
export const API_GET_STORES_ITEMS = '/store/getAllStoreItem/';
export const API_GET_STORES_ITEM_BY_ID = '/store/getItemById/';
export const API_ADD_STORES_ITEM = '/store/addStoreItem/';
export const API_EDIT_STORES_ITEM = '/store/updateStoreItem';
export const API_DELETE_STORES_ITEM = '/store/deleteStoreItem';
export const API_UPDATE_STORE_ITEM_STATUS = '/store/updateItemStatus';

// Drivers
export const API_GET_DRIVER_URL = '/admin/driver/driversList/';
export const API_GET_DRIVER = '/admin/driver/getDriverById/';
export const API_ADD_DRIVER = '/driver/registerbyadmin/';
export const API_EDIT_DRIVER = '/admin/driver/updateDriver/';
export const API_DELETE_DRIVER = '/admin/driver/deleteDriver/';
export const API_DRIVER_PAYMENTS = '/admin/driver/getAllDriverTransactionsById/';
export const API_STATUS_CHANGE_RIDER = '/admin/driver/statusChange/';
export const API_BLOCK_RIDER = '/admin/driver/blockUnblock/';

// Customers URLs
export const API_GET_CUSTOMERS_URL = '/admin/getUsersWithFilter/';
export const API_GET_CUSTOMER = '/admin/getUserById/';
export const API_ADD_CUSTOMER = '/admin/addUser/';
export const API_EDIT_CUSTOMER = '/admin/editUser/';
export const API_DELETE_CUSTOMER = '/admin/removeUser/';
export const API_UPLOAD_CUSTOMER = '/user/importCsvList/';

// adminNotes
export const API_ADMIN_NOTE = '/admin/order/adminNotes/';






//Admin Setting URLs
export const API_GET_ALL_ADMINS = '/admin/getAllAdminList/';
export const API_EDIT_ADMIN = '/admin/editAdmin/';
export const API_ADD_ADMIN = '/admin/addAdmin/';
export const API_GET_ADMIN = '/admin/getUserById/';
export const API_POST_ADMIN_BANNER = '/admin/bannerImage';

// Content pages
export const API_GET_CONTENT_PAGES_URL = '/admin/contents/';
export const API_EDIT_CONTENT_PAGE = '/admin/contents/update';
export const API_GET_CONTENT_PAGE_BY_ID = '/admin/contents/edit/';

// Faq
export const API_GET_ALL_FAQ = '/admin/getAllFaqs/';
export const API_ADD_FAQ = '/admin/addFaq/';
export const API_EDIT_FAQ = '/admin/editFaq/';
export const API_GET_FAQ = '/admin/getFaqsById/';
export const API_DELETE_FAQ = '/admin/deleteFaq/';

///// Settings

//Mail Templates Url
export const API_GET_MAIL_TEMPLATES = '/adminSetting/getMailTitle/';
export const API_GET_MAIL_TEMPLATE = '/adminSetting/getMailTemplateById/';
export const API_ADD_MAIL_TEMPLATE = '/adminSetting/addMailTemplate/';
export const API_EDIT_MAIL_TEMPLATE = '/adminSetting/editMailTemplate/';

//Sms Templates Url
export const API_GET_SMS_TEMPLATES = '/adminSetting/getSmsTemplate/';
export const API_GET_SMS_TEMPLATE = '/adminSetting/getSmsTemplateById/';
export const API_ADD_SMS_TEMPLATE = '/adminSetting/addSmsTemplate/';
export const API_EDIT_SMS_TEMPLATE = '/adminSetting/editSmsTemplate/';


// Car Types Urls
export const API_GET_ALL_CAR_TYPES = '/admin/cartypes/';
export const API_GET_CAR_TYPE_BY_ID = '/admin/cartypes/edit/';
export const API_ADD_CAR_TYPE = '/admin/addcartypes/';
export const API_EDIT_CAR_TYPE = '/admin/updatecartype/';

// pricing
export const API_GET_ALL_PRICING = '/admin/pslist/';
export const API_GET_PRICING_BY_ID = '/admin/psdetails/';
export const API_ADD_PRICING = '/admin/addps/';
export const API_EDIT_PRICING = '/admin/updateps/';
export const API_REMOVE_PRICING = '/admin/removeps/';

// Trips URLsapi/v1/admin/orderdetails/
export const API_GET_ORDERS = '/adminDashboard/allTripsWithFilter/'
export const API_GET_ORDER_BY_ID = '/admin/order/'

// Promo Code URLs
export const API_GET_ALL_PROMOCODE = '/adminSetting/getPromoCodeList/'
export const API_ADD_PROMOCODE = '/adminSetting/addPromoCode/'
export const API_EDIT_PROMOCODE = '/adminSetting/editPromocode/'
export const API_UPDATE_PROMOCODE_STATUS = '/adminSetting/updatePromocodeStatus/'
export const API_GET_PROMOCODE = '/adminSetting/getPromocodeDetails/'
export const API_DELETE_PROMOCODE = '/adminSetting/promocodeDelete/'

// Push Notification URLs
export const SEND_PUSH_NOTIFICATION = "admin/sendNotification"



export const API_GET_UBER_WEBHOOK = '/adminDashboard/getUberWebHookWithOrderId/';

// Settings
export const API_UPDATE_ADMIN_PASSWORD = '/adminSetting/changePassword/';
export const API_GET_BASIC_SETTINGS = '/adminSetting/getBasicsettinginfo';
export const API_UPDATE_BASIC_SETTINGS = '/adminSetting/basicAppSetting';
export const API_UPDATE_NOTIFICATION_SETTINGS = '/adminSetting/notificationSettingUpdate/';
export const API_UPDATE_SOCIAL_SETTINGS = '/adminSetting/basicSocialSetting';
export const API_INSTALLATION_SETTINGS = '/adminSetting/getConfigInfo';
export const API_UPDATE_SMS_INSTALLATION_SETTINGS = '/adminSetting/twilioUpdate';
export const API_UPDATE_PAYMENT_INSTALLATION_SETTINGS = '/adminSetting/PaymentConfigUpdate';
export const API_UPDATE_MAILGUN_INSTALLATION_SETTINGS = '/adminSetting/MailGunConfigUpdate';
export const API_UPDATE_ANDROID_INSTALLATION_SETTINGS = '/adminSetting/AndroidAppUrlUpdate';
export const API_UPDATE_IOS_INSTALLATION_SETTINGS = '/adminSetting/IOSAppURLUpdate';
export const API_GET_MAILTEMPLATE = '/adminSetting/getMailTitle';
export const API_UPDATE_MAILTEMPLATE = '/adminSetting/editMailTemplate';
export const API_GET_SMSTEMPALTE = '/adminSetting/getSmsTemplate';
export const API_UPDATE_SMSTEMPALTE = '/adminSetting/editSmsTemplate';



// Payment URLs
export const API_GET_PAYMENT_HISTORY = '/paymentdotnet';
export const API_GET_ONE_PAYMENT = '/paymentdotnet/';