export const provinceTaxes = {
  ontario: 13,
  quebec: 14.975,
  alberta: 5.0,
  manitoba: 0.53,
  britishColumbia: 5.0,
  saskatchewan: 5.0,
  newBrunswick: 5.0,
  novaScotia: 15.0,

  yukon: null,
  nunavut: null,
  princeEdwardIsland: null,
  northwestTerritories: null,
  newfoundlandAndLabrador: null,
};


export function getPriceWithoutTax(total, provinceName) {
  const provinceTax = provinceTaxes[provinceName] ? provinceTaxes[provinceName] : 0;
  const subTotal = total / (1 + provinceTax / 100);
  return (subTotal / 100).toFixed(2);
}

export function getPriceWithTax(total , provinceName) {
  const provinceTax = provinceTaxes[provinceName] ? provinceTaxes[provinceName] : 0;
  return total * (1 + provinceTax / 100);
}



export function roundToTwo(num) {
  return +(Math.round(num + "e+2")  + "e-2");
}