import React from 'react';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';

{/* Footer Component */}
const Footer = () => {
  
  return (

    /* Center the text and make the background transparent */
    <footer className="app-footer" style={{"background-color": "transparent", "display": "flex", "justify-content": "center"}}>

      {/* Set the text color to gray */}
      {/* <span className="d-inline-block"><a target="_blank" href="https://www.suffescom.com" style={{"color": "gray"}}>
        Suffescom.com Pvt. Ltd</a> &copy; {getYear()}
      </span> */}

    </footer>
  );
};

const getYear = () => {
    let date = new Date();
    return date.getFullYear();
}

export default Footer;
