import React from 'react';
import { Redirect, Route, Switch, asyncComponent } from '../../../components';
const Payment = ({ match }) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={asyncComponent(() => import('./routes/List'))} />
            <Route path={`${match.url}/view/:editId`} component={asyncComponent(() => import('./routes/view'))} />


        </Switch>
    </div>
);

export default Payment;
