const Status = {
  created: 'Registered',
  registered: 'Registered',
  approved: 'Approved',
  rejected: 'Rejected',
  Online: 'Online',
  Offline: 'Offline',
  true: 'Yes',
  false: 'No',
  yes: 'Yes',
  no: 'No',
  approved: 'Approved',
  created: 'New',
  rejected: 'Rejected',
  draft: 'Draft',
  active: 'Active',
  inactive: 'In Active',
  confirmed: 'Confirmed',
  searching: 'Searching',
  schedule: 'scheduled',
  started: 'Started',
  completed: 'Completed',
  cancel: 'Cancelled',
  Offline: 'Offline',
  active: 'Active',
  pending: 'Pending',
  cancelled: 'Cancelled',
  returned: 'Returned',
  return_on_route: 'Return on Route',
  declined: 'Declined',
  picked: 'Picked',
  assigned: 'Assigned',
  draft: 'Draft',
  new: 'New',
  refill: 'Refill',
  rx: 'Rx',
  otc: 'OTC',
  cash: 'Cash',
  stripe: 'Card',

  pending: 'Pending',
  sent: 'Sent',
  confirm: 'Confirm',

  // "pending", "confirmed", "declined", "picked", "completed", "cancelled"
};
export default Status;
